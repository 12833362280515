"use client";

import { CheckOutlined } from "@ant-design/icons";
import HeroArenaGif from "./Homepage Hero Area.gif";
import Image from "next/image";
import FadeIn from "@/components/shared/FadeIn";
import ContactHero from "../ContactHero";

const HeroCRM = () => {
  return (
    <div className="mt-12 row">
      <div className="col-12 col-md-6">
        <h1
          style={{ lineHeight: "48px" }}
          className="font-bold text-3xl text-adahubSecondary">
          PHẦN MỀM QUẢN LÝ BÁN HÀNG
          <br /> TÍCH HỢP TỔNG ĐÀI THÔNG MINH
        </h1>
        <FadeIn className="mt-9 flex flex-column gap-y-3 max-w-[380px]">
          <div className="flex flex-row items-center">
            Giải pháp CRM hỗ trợ toàn diện cho các hoạt động Marketing - Bán
            hàng - Chăm sóc khách hàng - Quản lý trên một nền tảng giúp doanh
            nghiệp quản lý khách hàng đồng bộ, tối ưu vận hành, bứt phá doanh
            thu
          </div>
        </FadeIn>
        <FadeIn
          delay={700}
          transitionDuration={700}
          className="max-w-[460px] mt-10 mb-4 transition-opacity ease-in duration-2400">
          <Image
            // placeholder="blur"
            src={HeroArenaGif}
            alt="callbot product image"
          />
        </FadeIn>
      </div>
      <div className="col-12 col-md-6">
        <ContactHero />
      </div>
    </div>
  );
};

export default HeroCRM;