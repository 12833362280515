import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@ant-design/icons/es/components/AntdIcon.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/accordion/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/autocomplete/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/avatar/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/badge/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/breadcrumbs/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/button/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/calendar/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/card/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/checkbox/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/chip/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/date-input/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/date-picker/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/dropdown/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ResizablePanel"] */ "/vercel/path0/node_modules/@nextui-org/framer-utils/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/image/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/input/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/link/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/listbox/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/menu/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/modal/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/navbar/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/pagination/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/popover/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/progress/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/radio/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/ripple/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/scroll-shadow/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/select/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/skeleton/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/slider/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/snippet/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/switch/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/system/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/table/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/tabs/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/tooltip/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@nextui-org/user/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/react-fast-marquee/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/AdahubHeader/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Banner4/background.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Banner5/background.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/ContactForm/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/ContactUsForm/background.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 109.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 111.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 112.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 113.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 114.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 79.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 80.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 81.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 82.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 85.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 87.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 88.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 89.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 90.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 91.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 92.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 95.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/image 96.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers/logodatxanh.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Features/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/FeaturesCrm/image (6).png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/FeaturesCrm/image (7).png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/FeaturesCrm/image (8).png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/FeaturesCrm/image (9).png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/HeroCRM/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/PricingCRM/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/SolutionsCRM/Group 1000004124.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/SolutionsCRM/Group 1000004125.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/SolutionsCRM/Group 1000004127.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Testimonial/image (6).png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Testimonial/image (7).png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/Testimonial/image 18.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/UseCasesGrid/image 33.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/UseCasesGrid/image 34.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/UseCasesGrid/image 35.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/UseCasesGrid/image 36.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/UseCasesGrid/image 37.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/UseCasesGrid/image 38.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/[locale]/(landingPage)/(homepageLayout)/home/components/WhyChooseCRM/index.tsx");
